import React, { Component } from "react";
import Config from "./../data/Data";
import Share from "./Share";
import $ from 'jquery';
import { Document, Page, pdfjs } from "react-pdf";
import PDF from "../data/policies/politicas_sgsi_2024.pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class Footer extends Component {
  componentDidMount() {
    $(".smodal-click").on("click", function(e) {
      e.preventDefault();
      $(".smodal").fadeIn(300);

      $(document).mouseup(function(e) {
        var container = $(".smodal-content");

        if (!container.is(e.target) && container.has(e.target).length === 0)  {
          $(".smodal").hide();
        }
      });

    });

    $(".smodal-close").on("click", function() {
      $(".smodal").hide();
    });

    const collapse = document.getElementsByClassName("collapse-title");

    for (let i = 0; i < collapse.length; i++) {
      collapse[i].addEventListener("click", function () {
        const width = window.innerWidth;

        if (width < 991) {
          let title = this.classList;
          let next = this.nextSibling;
          title.toggle("active");

          if (title.contains("active")) {
            next.classList.add("active");

            next.style.height = "0px";
            setTimeout(function () {
              next.style.height = "auto";
            }, 0);
          } else {
            next.classList.remove("active");
            next.removeAttribute("style");
            next.style.height = "auto";
          }
        }
      });
    }
  }

  render() {
    const {
      siteCopyright,
      siteDescription,
      numberWhatsapp,
      siteLegal,
      siteEmail,
      siteLocation,
      numberPhone2,
      siteLogoFooter,
      siteName,
      siteSlogan,
    } = Config;

    const formatWhatapp = numberWhatsapp
      .toString()
      .replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, " ");

    const Style = {
      display: "block",
    };

    return (
      <footer className="text-gray-200 overflow-hidden">
        <div className="mx-n3 mx-lg-0">
          <div className="container py-lg-3">
            <div className="row py-lg-5">
              <div className="col-lg-3 d-none d-lg-block">
                <div className="mb-4">
                  <img
                    src={siteLogoFooter}
                    alt={`${siteName} - ${siteSlogan}`}
                  />
                </div>
                <p className="pr-lg-5 mb-0">
                  <em>{siteDescription}</em>
                </p>
              </div>
              <div className="col-lg-3">
                <div className="collapse-title">
                  <h3 className="h6 font-lg-h5 mb-0 mb-lg-4 text-gray-100">
                    Servicios
                  </h3>
                </div>
                <div className="collapse-content">
                  <ul className="list-unstyled mb-0">
                    <li>
                      <a href="/facturacion-electronica">Facturación electrónica</a>
                    </li>
                    <li className="my-2">
                      <a href="/consultoria">Consultoría de desarrollo</a>
                    </li>
                    <li>
                      <a href="/laboratorio">Laboratorio de innovación</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="collapse-title">
                  <h3 className="h6 font-lg-h5 mb-0 mb-lg-4 text-gray-100">
                    Redes sociales
                  </h3>
                </div>
                <div className="collapse-content">
                  <ul className="list-unstyled mb-0 h5 font-weight-normal d-flex">
                    <Share />
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="">
                  <div className="collapse-title active">
                    <h3 className="h6 font-lg-h5 mb-0 mb-lg-4 text-gray-100">
                      Contáctenos
                    </h3>
                  </div>
                  <div className="collapse-content" style={Style}>
                    <div className="d-flex mb-1 pb-1">
                      <div className="h5 mb-0 font-weight-normal mr-3">
                        <i className="fas fa-phone-alt"></i>
                      </div>
                      <div>
                        <span className="d-block">{numberPhone2}</span>
                      </div>
                    </div>
                    <div className="d-flex mb-1 pb-1">
                      <div className="h5 mb-0 font-weight-normal mr-3">
                        <i className="fab fa-whatsapp text-green"></i>
                      </div>
                      <div>
                        <a
                          href={`https://wa.me/51${numberWhatsapp}`}
                          className="none"
                        >
                          {formatWhatapp}
                        </a>
                      </div>
                    </div>
                    <div className="d-flex mb-1 pb-1">
                      <div className="h5 mb-0 font-weight-normal mr-3">
                        <i className="fas fa-envelope"></i>
                      </div>
                      <div>
                        <a href={`mailto:${siteEmail}`} className="none">
                          {siteEmail}
                        </a>
                      </div>
                    </div>
                    <div className="d-flex mb-0">
                      <div className="h5 mb-0 font-weight-normal mr-3">
                        <i className="fas fa-map-marker-alt"></i>
                      </div>
                      <div className=" mb-0">{siteLocation}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-blue-500 text-gray-400 py-3 small text-center text-lg-left">
          <div className="container">
            <div className="d-lg-flex justify-content-between flex-row-reverse">
              <ul className="list-unstyled d-flex mb-2 mb-lg-0 pb-1 pb-lg-0 justify-content-center">
                <li>
                  <span className="seePolicies smodal-click">
                    Política SGSI
                  </span>
                </li>
                <li className="ml-4">
                  <a href="https://help.doous.com/privacidad/politicas-de-privacidad" target="_blank"
                   className="text-gray-400">
                    Política de privacidad
                  </a>
                </li>
                <li className="ml-4">
                  <a href="https://help.doous.com/privacidad/terminos-y-condiciones" target="_blank"
                   className="text-gray-400">
                    Términos y condiciones
                  </a>
                </li>
              </ul>
              <p className="mb-0 d-md-flex flex-row-reverse justify-content-center">
                <span className="d-block">
                  <span className="d-none d-md-inline-block px-1">-</span>
                  {siteLegal}
                </span>
                <span className="d-block">{siteCopyright}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="smodal">
          <div className="smodal-wrapper">
            <div id="info" className="smodal-content">
              <div className="smodal-close">x</div>
                <div className="smodal-text">
                  <div className="pdf-content">
                    <Document file={PDF} >
                      <Page scale={1.25} pageNumber={1} />
                    </Document>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>

    );
  }
}

export default Footer;
